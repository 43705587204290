import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { PATH } from '../../constants/global'
import { useAPI, useSettings } from '..'
import {
   UseTableSortColumnOptions,
   TableSortColumnOption,
} from './useTableSortColumnOptions.type'

// Fetches table sortable header options for sort panel
export default function (): UseTableSortColumnOptions {
   const request = useAPI()

   const { data, isLoading, refetch } = useQuery(
      'table-sort-column-options',
      getTableSortColumnsFromAPI
   )

   async function getTableSortColumnsFromAPI(): Promise<
      AxiosResponse<TableSortColumnOption[]>
   > {
      try {
         return await request.get(PATH.TABLE.SORT_COLUMN_OPTIONS) //TODO: create new and change path
      } catch (error) {
         // TODO: handle errors better
      }
   }

   return {
      isLoading,
      list: data?.data || [],
      refetchTableSortColumnOptions: refetch,
   }
}
